import React from 'react'
import BannerSlide from './BannerSlide'

const Homepage = () => {
  return (
    <div className="p-4">
      <BannerSlide />
    </div>
  )
}

export default Homepage
