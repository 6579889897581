import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Table, Container, Tooltip, OverlayTrigger, Modal, Form, Button } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { FaEdit, FaKey } from "react-icons/fa";
import Swal from 'sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';

const MasterUserApproval = () => {
    const [users, setUsers] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [selectedUser, setSelectedUser] = useState(null);
    const [statuse, setStatuse] = useState('');
    const [reason, setReason] = useState('');

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_SERVER}/users`)
            .then(response => {
                setUsers(response.data);
            })
            .catch(error => {
                console.error('There was an error fetching the users!', error);
            });
    }, []);

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');
        const seconds = String(date.getSeconds()).padStart(2, '0');
        return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    };

    const handleEditClick = (user) => {
        setSelectedUser(user);
        setStatuse(user.status_submission);
        setReason(user.reason);
        setShowModal(true);
    };

    const handleResetPassword = (user) => {
        Swal.fire({
            title: `Apakah anda yakin akan melakukan reset password ke user ${user.username}?`,
            icon: 'question',
            showCancelButton: true,
            confirmButtonText: 'Ya, reset',
            cancelButtonText: 'Batal',
            confirmButtonColor: '#d33',  // Mengubah warna tombol konfirmasi menjadi merah
            cancelButtonColor: '#3085d6',  // Mengubah warna tombol batal
        }).then((result) => {
            if (result.isConfirmed) {
                axios.put(`${process.env.REACT_APP_SERVER}/users_reset/${user.user_id}`)
                    .then(response => {
                        Swal.fire({
                            icon: 'success',
                            title: 'Berhasil!',
                            text: 'Reset password berhasil!',
                            confirmButtonText: 'OK',
                            confirmButtonColor: '#d33'  // Mengubah warna tombol OK menjadi merah
                        });
                    })
                    .catch(error => {
                        console.error('There was an error updating the user!', error);
                        Swal.fire({
                            icon: 'error',
                            title: 'Gagal!',
                            text: 'Terjadi kesalahan saat mereset password.',
                            confirmButtonText: 'OK',
                            confirmButtonColor: '#d33'  // Mengubah warna tombol OK menjadi merah
                        });
                    });
            }
        });
    };

    const handleSave = () => {
        // Save the changes to the server
        axios.put(`${process.env.REACT_APP_SERVER}/users/${selectedUser.user_id}`, { statuse, reason })
            .then(response => {
                // Update user list
                setUsers(users.map(user =>
                    user.user_id === selectedUser.user_id ? { ...user, statuse, reason } : user
                ));
                setShowModal(false);
            })
            .catch(error => {
                console.error('There was an error updating the user!', error);
            });
    };


    const renderTooltip = (user) => (
        <Tooltip>
            <div className="text-start nowrap">
                CR : {formatDate(user.created_at)}<br />
                UP : {formatDate(user.updated_at)}
            </div>
        </Tooltip>
    );

    const renderReasonTooltip = (user) => (
        <Tooltip>
            <div className="text-start nowrap">
                {user.reason}
            </div>
        </Tooltip>
    );

    return (
        <div className="m-4 p-4 rounded-3 vh-100" style={{ backgroundColor: '#FFF' }}>
            <header className="mb-4">
                <h5>User Management</h5>
                Anda dapat melakukan Approval status user atau melakukan Rejected serta menghapus user
            </header>
            <Container style={{ fontSize: '11px' }}>
                <Table className="table-sm" striped bordered hover responsive>
                    <thead>
                        <tr>
                            <th className="nowrap text-center">NO</th>
                            <th className="nowrap text-center">USER ID</th>
                            <th className="nowrap text-center">USERNAME</th>
                            <th className="nowrap text-center">NAMA PERUSAHAAN</th>
                            <th className="nowrap text-center">HANDPHONE</th>
                            <th className="nowrap text-center">STATUS</th>
                            <th className="nowrap text-center">EVENT DATE</th>
                            <th className="nowrap text-center">EDIT</th>
                        </tr>
                    </thead>
                    <tbody>
                        {users.map((user, index) => (
                            <tr key={index}>
                                <td className="nowrap text-center">{index + 1}</td>
                                <td className="nowrap text-center">{user.user_id}</td>
                                <td className="nowrap">{user.username}</td>
                                <td className="nowrap">{user.nama_perusahaan}</td>
                                <td className="nowrap text-center">{user.nomor_hp}</td>
                                <td className="nowrap text-center">
                                    <OverlayTrigger
                                        placement="top"
                                        overlay={renderReasonTooltip(user)}
                                    >
                                        <span>{user.status_submission}</span>
                                    </OverlayTrigger>
                                </td>
                                <td className="nowrap text-center">
                                    <OverlayTrigger
                                        placement="top"
                                        overlay={renderTooltip(user)}
                                    >
                                        <span>{formatDate(user.created_at).split(' ')[0]}</span>
                                    </OverlayTrigger>
                                </td>
                                <td className="nowrap text-center">
                                    <FaEdit onClick={() => handleEditClick(user)} style={{ cursor: 'pointer' }} /> &nbsp;
                                    <FaKey onClick={() => handleResetPassword(user)} style={{ cursor: 'pointer' }} />
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
            </Container>

            {/* Modal */}
            <Modal show={showModal} onHide={() => setShowModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Edit User</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group className="mb-3" controlId="status">
                            <Form.Label>Status</Form.Label>
                            <Form.Control
                                as="select"
                                value={statuse}
                                onChange={(e) => setStatuse(e.target.value)}
                            >
                                <option value="APPROVED">APPROVED</option>
                                <option value="PENDING">PENDING</option>
                                <option value="REJECTED">REJECTED</option>
                            </Form.Control>
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="reason">
                            <Form.Label>Reason</Form.Label>
                            <Form.Control
                                as="textarea"
                                rows={3}
                                value={reason}
                                onChange={(e) => setReason(e.target.value)}
                            />
                        </Form.Group>
                        <div className="text-end">
                            <Button variant="danger" onClick={handleSave}>Save Changes</Button>
                        </div>
                    </Form>
                </Modal.Body>
            </Modal>
        </div>
    );
};

export default MasterUserApproval;
