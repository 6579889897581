import React, { useState } from 'react';
import axios from 'axios';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Alert from 'react-bootstrap/Alert';
import Swal from 'sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';

const MAX_FILE_SIZE_MB = 10; // Maksimal ukuran file dalam MB

const FormDokumenPengajuan = ({ uid, username }) => {
    const [error, setError] = useState('');
    const [file, setFile] = useState(null);

    const handleChange = (e) => {
        const selectedFile = e.target.files[0];

        if (selectedFile) {
            if (!['application/zip', 'application/x-zip-compressed', 'multipart/x-zip', 'application/x-compressed'].includes(selectedFile.type)) {
                Swal.fire({
                    icon: 'error',
                    title: 'File Tidak Valid',
                    text: 'Hanya file .zip yang diperbolehkan!',
                });
                setFile(null);
                return;
            }
            if (selectedFile.size > MAX_FILE_SIZE_MB * 1024 * 1024) { // Convert MB to bytes
                Swal.fire({
                    icon: 'error',
                    title: 'Ukuran File Terlalu Besar',
                    text: `Ukuran file tidak boleh lebih dari ${MAX_FILE_SIZE_MB} MB!`,
                });
                setFile(null);
                return;
            }

            setError('');
            setFile(selectedFile);
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!file) {
            Swal.fire({
                icon: 'warning',
                title: 'File Tidak Ditemukan',
                text: 'File tidak boleh kosong!',
            });
            return;
        }

        const usernamePart = `${uid}_${username.split('@')[0]}`;
        const formData = new FormData();
        formData.append('username', username);

        const renamedFile = new File([file], `${usernamePart}_dokumen.zip`, {
            type: file.type,
        });
        formData.append('dokumenPengajuan', renamedFile);

        try {
            const response = await axios.post(`${process.env.REACT_APP_SERVER}/uploadDokumen`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });

            if (response.data.success) {
                Swal.fire({
                    icon: 'success',
                    title: 'Berhasil',
                    text: 'Data perusahaan berhasil disimpan',
                });
                setFile(null);
            } else {
                Swal.fire({
                    icon: 'error',
                    title: 'Gagal',
                    text: 'Gagal menyimpan data perusahaan',
                });
            }
        } catch (error) {
            console.error('Terjadi kesalahan:', error);
            Swal.fire({
                icon: 'error',
                title: 'Terjadi Kesalahan',
                text: 'Terjadi kesalahan saat menyimpan data perusahaan',
            });
        }
    };

    return (
        <>
            <p className="mb-4"><b>Dokumen Pengajuan</b></p>
            <Alert key="info" variant="info">File yang di upload harus berbentuk .zip dengan max file size 10MB.</Alert>
            {error && <Alert variant="danger">{error}</Alert>}
            
            <Form onSubmit={handleSubmit}>
                <Container style={{ borderBottom: '0px solid #CCC' }} className="pb-4 mb-4">
                    <Row className='pb-0 mb-0'>
                        <Col xs={12} className="px-0">
                            <span style={{ fontSize: '12px' }}>DOKUMEN PENGAJUAN <span className="text-danger"><b>*</b></span></span>
                        </Col>
                        <Col xs={12} className="px-0">
                            <Form.Control size="sm" type="file" name="dokumenPengajuan" onChange={handleChange} style={{ width: '100%' }} required />
                            <p className="mt-1 mx-2" style={{ fontSize: '11px', color: '#999' }}>Max : 10MB | ZIP</p>
                        </Col>
                    </Row>
                    <Row className='pb-2 mb-0'>
                        <Col xs={12} className="pt-0 px-0 text-end">
                            <Button type="submit" className="bg-danger text-white" style={{ border: '0px solid black' }}>Simpan</Button>
                        </Col>
                    </Row>
                </Container>
            </Form>
        </>
    );
};

export default FormDokumenPengajuan;
