import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Swal from 'sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';

const MAX_FILE_SIZE_MB = 5; // Maximum file size in MB

const FormInformasiPerusahaan = ({ uid, username, nama_perusahaan }) => {
    const [formData, setFormData] = useState({
        bentukUsaha: '',
        kategoriMitra: '',
        alamatKantor: '',
        alamatKorespondensi: '',
        nomorTelepon: '',
        nomorFax: '',
        emailKantor: '',
        logoPerusahaan: null,
    });

    useEffect(() => {
        fetch(`${process.env.REACT_APP_SERVER}/getInformasiPerusahaan`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ username })
        })
        .then(response => response.json())
        .then(data => {
            setFormData({
                nama_perusahaan: data[0].nama_perusahaan || '',
                bentukUsaha: data[0].bentuk_perusahaan || '',
                kategoriMitra: data[0].kategori_mitra || '',
                alamatKantor: data[0].alamat_kantor || '',
                alamatKorespondensi: data[0].alamat_korespondensi || '',
                nomorTelepon: data[0].nomor_telepon || '',
                nomorFax: data[0].nomor_fax || '',
                emailKantor: data[0].email_kantor || '',
                logoPerusahaan: data[0].logo_perusahaan || ''
            });
        })
        .catch(error => console.error('Error fetching data:', error));
    }, [username]);

    const handleChange = (e) => {
        const { name, value, files } = e.target;

        if (name === 'logoPerusahaan' && files.length > 0) {
            const file = files[0];
            if (file.type !== 'image/png') {
                Swal.fire({
                    icon: 'error',
                    title: 'File tidak valid',
                    text: 'Hanya file .png yang diperbolehkan!',
                    confirmButtonText: 'Kembali',
                    confirmButtonColor: '#d33'
                });
                return;
            }
            if (file.size > MAX_FILE_SIZE_MB * 1024 * 1024) { // Convert MB to bytes
                Swal.fire({
                    icon: 'error',
                    title: 'Ukuran file terlalu besar',
                    text: `Ukuran file tidak boleh lebih dari ${MAX_FILE_SIZE_MB} MB!`,
                    confirmButtonText: 'Kembali',
                    confirmButtonColor: '#d33'
                });
                return;
            }

            setFormData((prevData) => ({
                ...prevData,
                [name]: file,
            }));
        } else {
            setFormData((prevData) => ({
                ...prevData,
                [name]: value,
            }));
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        // Extract username before '@'
        const usernamePart = `${uid}_${username.split('@')[0]}`;

        const requiredFiles = ['logoPerusahaan'];
        for (const fileName of requiredFiles) {
            if (!formData[fileName]) {
                Swal.fire({
                    icon: 'warning',
                    title: 'File belum dipilih',
                    text: `Anda belum memilih ${fileName} dengan sesuai!`,
                    confirmButtonText: 'OK',
                    confirmButtonColor: '#d33'
                });
                return; // Stop form submission
            }
        }

        // Create FormData object
        const data = new FormData();
        data.append('username', username);
        data.append('nama_perusahaan', nama_perusahaan);
        data.append('bentukUsaha', formData.bentukUsaha);
        data.append('kategoriMitra', formData.kategoriMitra);
        data.append('alamatKantor', formData.alamatKantor);
        data.append('alamatKorespondensi', formData.alamatKorespondensi);
        data.append('nomorTelepon', formData.nomorTelepon);
        data.append('nomorFax', formData.nomorFax);
        data.append('emailKantor', formData.emailKantor);

        // Rename and add file to FormData if it exists
        if (formData.logoPerusahaan) {
            const renamedFile = new File([formData.logoPerusahaan], `${usernamePart}_logo.png`, {
                type: formData.logoPerusahaan.type,
            });
            data.append('logoPerusahaan', renamedFile);
        }

        try {
            const response = await axios.post(`${process.env.REACT_APP_SERVER}/insertPerusahaan`, data, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });

            if (response.data.success) {
                Swal.fire({
                    icon: 'success',
                    title: 'Sukses!',
                    text: 'Data perusahaan berhasil disimpan',
                    confirmButtonText: 'OK',
                    confirmButtonColor: 'green'
                });
            } else {
                Swal.fire({
                    icon: 'error',
                    title: 'Gagal!',
                    text: 'Gagal menyimpan data perusahaan',
                    confirmButtonText: 'Kembali',
                    confirmButtonColor: '#d33'
                });
            }
        } catch (error) {
            console.error('Terjadi kesalahan:', error);
            Swal.fire({
                icon: 'error',
                title: 'Terjadi kesalahan',
                text: 'Terjadi kesalahan saat menyimpan data perusahaan',
                confirmButtonText: 'Kembali',
                confirmButtonColor: '#d33'
            });
        }
    };

    return (
        <div>
            <p className="mb-4"><b>Input Data Perusahaan</b></p>
            <Form onSubmit={handleSubmit}>
                <Container style={{ borderBottom: '1px solid #CCC' }} className="pb-4 mb-4">
                    <Row className='pb-2'>
                        <Col xs={4} className="px-0">
                            <span style={{ fontSize: '12px' }}>NAMA PERUSAHAAN <span className="text-danger"><b>*</b></span></span>
                            <Form.Control size="sm" type="text" placeholder="" style={{ width: '90%' }} value={nama_perusahaan} readOnly/>
                        </Col>
                        <Col xs={4} className="p-0">
                            <span style={{ fontSize: '12px' }}>BENTUK USAHA <span className="text-danger"><b>*</b></span></span>
                            <Form.Select size="sm" name="bentukUsaha" onChange={handleChange} required style={{ width: '90%' }} value={formData.bentukUsaha}>
                                <option value="" disabled>Pilih Bentuk Usaha</option>
                                <option value="PT">PT</option>
                                <option value="CV">CV</option>
                                <option value="KOPERASI">KOPERASI</option>
                            </Form.Select>
                        </Col>
                        <Col xs={4} className="p-0">
                            <span style={{ fontSize: '12px' }}>KATEGORI MITRA <span className="text-danger"><b>*</b></span></span>
                            <Form.Select size="sm" name="kategoriMitra" onChange={handleChange} required style={{ width: '90%' }} value={formData.kategoriMitra}>
                                <option value="" disabled>Pilih Kategori</option>
                                <option value="EKSISTING">Mitra Eksisting</option>
                                <option value="NEW ENTRY">New Entry</option>
                            </Form.Select>
                        </Col>
                    </Row>
                </Container>
                <p className="mb-4"><b>Informasi Perusahaan</b></p>
                <Container className="pb-4 mb-4">
                    <Row className='pb-4'>
                        <Col xs={4} className="p-0">
                            <span style={{ fontSize: '12px' }}>ALAMAT KANTOR SESUAI NPWP <span className="text-danger"><b>*</b></span></span>
                            <Form.Control size="sm" type="text" name="alamatKantor" onChange={handleChange} style={{ width: '90%' }} value={formData.alamatKantor} required placeholder='Mandatory'/>
                        </Col>
                        <Col xs={4} className="p-0">
                            <span style={{ fontSize: '12px' }}>ALAMAT KORESPONDENSI <span className="text-danger"><b>*</b></span></span>
                            <Form.Control size="sm" type="text" name="alamatKorespondensi" onChange={handleChange} style={{ width: '90%' }} value={formData.alamatKorespondensi} required placeholder='Mandatory'/>
                        </Col>
                        <Col xs={4} className="p-0">
                            <span style={{ fontSize: '12px' }}>NOMOR TELEPON KANTOR <span className="text-danger"><b>*</b></span></span>
                            <Form.Control size="sm" type="text" name="nomorTelepon" onChange={handleChange} style={{ width: '90%' }} required value={formData.nomorTelepon} placeholder='Mandatory'/>
                        </Col>
                    </Row>
                    <Row className='pb-4'>
                        <Col xs={4} className="p-0">
                            <span style={{ fontSize: '12px' }}>NOMOR FAKSIMILI KANTOR </span>
                            <Form.Control size="sm" type="text" name="nomorFax" onChange={handleChange} placeholder="Optional" style={{ width: '90%' }} value={formData.nomorFax}/>
                        </Col>
                        <Col xs={4} className="p-0">
                            <span style={{ fontSize: '12px' }}>EMAIL KANTOR <span className="text-danger"><b>*</b></span></span>
                            <Form.Control size="sm" type="email" name="emailKantor" onChange={handleChange} style={{ width: '90%' }} required value={formData.emailKantor} placeholder='Mandatory'/>
                        </Col>
                        <Col xs={4} className="p-0">
                            <span style={{ fontSize: '12px' }}>LOGO PERUSAHAAN <span className="text-danger"><b>*</b></span></span>
                            <Form.Control size="sm" type="file" name="logoPerusahaan" onChange={handleChange} style={{ width: '90%' }} required/>
                            <p className="mt-1 mx-2" style={{ fontSize: '11px', color: '#999' }}>Max: {MAX_FILE_SIZE_MB} MB | file .PNG</p>
                        </Col>
                        <Col xs={12} className="pt-4 px-4 text-end">
                            <Button type="submit" className="bg-warning" style={{ border: '0px solid black' }}>Simpan</Button>
                        </Col>
                    </Row>
                </Container>
            </Form>
        </div>
    );
}

export default FormInformasiPerusahaan;
