import React from 'react';
import { FaHandshake, FaHome, FaBook, FaUser } from "react-icons/fa";
import { useNavigate } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import Swal from 'sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';

const Sidebar = ({ isOpen, tipe }) => {
    const navigate = useNavigate();

    const routesHome = () => {
        navigate(`/`);
    };

    const routesFormPengajuan = () => {
        navigate(`/form-pengajuan`);
    };

    const routesUserApproval = () => {
        navigate(`/master-user-approval`);
    };

    const handleLogout = async () => {
        // SweetAlert2 for confirmation
        const result = await Swal.fire({
            title: 'Are you sure?',
            text: "Do you want to log out?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, log out!',
            cancelButtonText: 'Cancel'
        });

        if (result.isConfirmed) {
            try {
                const response = await fetch(`${process.env.REACT_APP_SERVER}/logout`, {
                    method: 'POST',
                    credentials: 'include', // Include credentials to send cookies
                });

                const data = await response.json();
                if (data.loggedIn === false) {
                    sessionStorage.clear();
                    window.location.href = `${process.env.REACT_APP_FRONTEND}`;
                }
            } catch (error) {
                console.error('Error logging out:', error);
                Swal.fire({
                    icon: 'error',
                    title: 'Logout Failed',
                    text: 'An error occurred while trying to log out.',
                });
            }
        }
    };

    return (
        <nav id="sidebar" className={`sidebar js-sidebar ${isOpen ? 'open' : 'closed'}`}>
            <div className="sidebar-content js-simplebar">
                <div className="sidebar-brand d-flex">
                    <div className="p-1 text-center" style={{ borderRadius: '50%', width: '40px', backgroundColor: 'red' }}>
                        <FaHandshake className="mt-0" />
                    </div>
                    <span className="mx-2 align-middle" style={{ fontSize: '25px' }}>NGPP 3.0</span>
                </div>

                <ul className="sidebar-nav">
                    <li className="sidebar-header">
                        Pages
                    </li>

                    <li className="sidebar-item active">
                        <div className="sidebar-link" onClick={() => routesHome()}>
                            <FaHome />
                            <span className="align-middle">Home</span>
                        </div>
                    </li>

                    <li className="sidebar-item">
                        <div className="sidebar-link" onClick={() => routesFormPengajuan()}>
                            <FaBook />
                            <span className="align-middle">Form Pengajuan</span>
                        </div>
                    </li>

                    { /*
                    <li className="sidebar-item">
                        <div className="sidebar-link" href="pages-sign-in.html">
                            <FaHistory />
                            <span className="align-middle">File List</span>
                        </div>
                    </li>

                    

                    <li className="sidebar-item">
                        <a className="sidebar-link" href="ui-buttons.html">
                            <i className="align-middle" data-feather="square"></i> <span className="align-middle">Buttons</span>
                        </a>
                    </li>

                    <li className="sidebar-item">
                        <a className="sidebar-link" href="ui-forms.html">
                            <i className="align-middle" data-feather="check-square"></i> <span className="align-middle">Forms</span>
                        </a>
                    </li>
                    */}

                    {tipe === "0" && (
                        <>
                            <li className="sidebar-header">Administrator Menu</li>
                            <li className="sidebar-item">
                                <div className="sidebar-link" onClick={() => routesUserApproval()}>
                                    <FaUser />
                                    <span className="align-middle">User Approval</span>
                                </div>
                            </li>
                        </>
                    )}
                </ul>
                <div className="sidebar-cta">
                    <div className="sidebar-cta-content">
                        <div className="d-grid">
                            <Button onClick={handleLogout} className="btn btn-danger">Logout</Button>
                        </div>
                    </div>
                </div>
            </div>
        </nav>
    );
}

export default Sidebar;
