import React, { useState, useEffect } from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import Header from './components/Header';
import Sidebar from './components/Sidebar';
import Footer from './components/Footer';
import './App.css'; // pastikan ini untuk styling sidebar dan main content
import Homepage from './components/Homepage';
import FormPengajuan from './components/FormPengajuan';
import 'bootstrap/dist/css/bootstrap.min.css';
import LoginPage from './components/LoginPage';
import MasterUserApproval from './components/MasterUserApproval';

function App() {
  const [loggedIn, setLoggedIn] = useState(false);
  const [userData, setUserData] = useState({
    username: '',
    perusahaan: '',
    user_id: '',
    tipe: '',
  });
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  useEffect(() => {
    const storedUsername = sessionStorage.getItem("username");
    const storedPerusahaan = sessionStorage.getItem("perusahaan");
    const storedTipe = sessionStorage.getItem("tipe");
    const storedUserId = sessionStorage.getItem("user_id");


    if (storedUsername) {
      setLoggedIn(true);
      setUserData({
        username: storedUsername,
        perusahaan: storedPerusahaan,
        user_id: storedUserId,
        tipe: storedTipe
      });
    } else {
      setLoggedIn(false);
    }
  }, []);

  return (
    <>
      <BrowserRouter>
        {loggedIn ? (
          <div className={`wrapper ${isSidebarOpen ? 'sidebar-open' : 'sidebar-closed'}`}>
            <Sidebar isOpen={isSidebarOpen} tipe={userData.tipe}/>
            <div className="main">
              <Header toggleSidebar={toggleSidebar} uid={userData.user_id} username={userData.username} nama_perusahaan={userData.perusahaan} />
              <Routes>
                <Route path="/" element={<Homepage uid={userData.user_id} username={userData.username} nama_perusahaan={userData.perusahaan} />} />
                <Route path="/home" element={<Homepage uid={userData.user_id} username={userData.username} nama_perusahaan={userData.perusahaan} />} />
                <Route path="/master-user-approval" element={<MasterUserApproval uid={userData.user_id} username={userData.username} nama_perusahaan={userData.perusahaan}/>} />
                <Route path="/form-pengajuan" element={<FormPengajuan uid={userData.user_id} username={userData.username} nama_perusahaan={userData.perusahaan} />} />
              </Routes>
              <Footer />
            </div>
          </div>
        ) : (
          <Routes>
            <Route path="/" element={<LoginPage uid={userData.user_id} username={userData.username} nama_perusahaan={userData.perusahaan}  />} />
            <Route path="*" element={<Navigate to="/" />} />
          </Routes>
        )}
      </BrowserRouter>
    </>
  );
}

export default App;
